import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, Route, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UtilityService } from '../modules/shared/services/utility.service';
import { LOGIN } from '../constant/absolute-routes';
import { LoaderService } from '../modules/shared/services/loader.service';
import { DataTransferService } from '../modules/shared/services/data-transfer.service';

@Injectable()
export class HomeGuard implements CanActivate, CanLoad {
  constructor(
    private _router: Router,
    private _utilityService: UtilityService,
    private _loaderService: LoaderService,
    private _dataTransferService:DataTransferService
  ) {

  }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this._utilityService.getAuthToken()) {
      // this._dataTransferService.getProfileDetail(true);
      // return true;
      return this.tokenVerify();
    }

    return this.navigate();

  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    if (this._utilityService.getAuthToken()) {
      // this._dataTransferService.getProfileDetail(true);
      // console.log('----home');
      // return true;
      return this.tokenVerify();
    }
    return this.navigate();
  }

  async tokenVerify() {

    await this._dataTransferService.getprofileData(true);
    console.log('----home');
    return true;
  }
  navigate() {
    this._utilityService.clearStorage();
    this._router.navigate([LOGIN]);
    this._loaderService.hideLoader();
    return false;
  }
}
