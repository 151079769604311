import { Component, OnInit, HostListener, Renderer2 } from "@angular/core";
import {
  POPUP_MESSAGES,
  SOMETHING_WENT_WRONG
} from "../../../../constant/messages";
import { UtilityService } from "../../../shared/services/utility.service";
import { HttpService } from "../../../shared/services/http.service";
import { Router } from "@angular/router";
import { DataTransferService } from "../../../shared/services/data-transfer.service";
import { LOGOUT } from "src/app/constant/url";
import { LOGIN } from "../../../../constant/absolute-routes";
import { Subscription } from "rxjs/internal/Subscription";
import { HeaderService } from "./service/header.service";
import { AutoUnsubscribe } from 'src/app/constant/unsubscriber';
import { PopupService } from 'src/app/common/popup';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
@AutoUnsubscribe([])
export class HeaderComponent implements OnInit {
  logo: string = "../../../../../assets/images/VegEase-logo.svg";
  flag = 1;
  subscription = new Subscription();
  profileSubscriber;
  profileDetail;
  adminName;
  showNotificationBadge: boolean = false;
  showLoadMoreNotifications: boolean;
  isNotificationsLoading: boolean;
  firstTimeNotifications = true;
  notificationsList;
  showReadAll = true;
  notificationPageNo = 1;
  notificationBadgeCount = 0;
  // @HostListener("window:resize", ["$event"])
  // onResize(event) {
  //   if (event.target.innerWidth < 992) {
  //     this.flag = 1;
  //   }
  // }

  constructor(
    private renderer: Renderer2,
    private _utilityService: UtilityService,
    private _http: HttpService,
    private _router: Router,
    private _dataService: DataTransferService,
    private $headerService: HeaderService,
    private $popup: PopupService
  ) {
    this.adminName = localStorage.getItem("jmart_admin-name");
  }

  sidebarCollaped() {
    if (this.flag === 1) {
      this.renderer.addClass(document.body, "collapsed");
      this.flag++;
    } else {
      this.renderer.removeClass(document.body, "collapsed");
      this.flag--;
    }
  }

  getProfileDetail() {
    this._dataService.getProfileDetail().subscribe((response: any) => {
      this.profileDetail = response.data;
      console.log(this.profileDetail)
      this._dataService.profileDetail.next(this.profileDetail);
    });

  }

  ngOnInit() {
    this.getProfileDetail();
    //   this.profileSubscriber = this._dataService.profileDetail.subscribe(
    //     data => {
    //       if (data)
    //         this.profileDetail = data;
    //     }
    //   )
  }

  logout() {
    let data = {
      title: POPUP_MESSAGES.logout,
      message: POPUP_MESSAGES.logoutConfirmation,
      yes: POPUP_MESSAGES.logout
    };
    this._utilityService.openDialog(data).subscribe(success => {
      if (success) {
        this._http.postEgreenApi(LOGOUT).subscribe(
          response => {
            // if (response['statusCode'] === 200) {
            this._utilityService.clearStorage();
            // this._router.navigate([LOGIN]); 
            window.location.href = LOGIN;
          }
          // }, err => {
          //   this._utilityService.showAlert(SOMETHING_WENT_WRONG, 'error')
          // }
        );
      }
    });
  }
  isCurrentDate(date): boolean {
    return new Date(date).getDate() === new Date().getDate();
  }


  ngOnDestroy() {
    if (this.profileSubscriber) {
      this.profileSubscriber.unsubscribe();
    }
  }
}
