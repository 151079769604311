<div class="page-layout-screen">
    <app-header></app-header>
       <div class="content-cover">
		<div class="sidebar">
			<app-sidebar></app-sidebar>
		</div>
		<div class="main-content">
			<!-- <app-breadcrumb class="page-header"></app-breadcrumb> -->
			<router-outlet></router-outlet>
		</div>
	</div>
	<app-footer></app-footer>
</div>