import { Component, OnInit } from '@angular/core';
import { Module } from 'src/app/constant/app-constant';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  MODULE_NAME = Module;

  constructor() { }

  ngOnInit(): void {
  }

}
