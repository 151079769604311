import { Injectable } from '@angular/core';
import { HttpService } from '../../../../shared/services/http.service';
import { NOTIFICATION_LIST, DELETE_NOTIFICATION } from 'src/app/constant/url';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  constructor(private $http: HttpService) { }

  getNotificationsList(data:any) {
    return this.$http.get(NOTIFICATION_LIST, data, false).toPromise();
  }

  deleteNotification(id:any, data:any) {
    return this.$http.delete(DELETE_NOTIFICATION(id),data).toPromise();
  }
}
