import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, EMPTY } from "rxjs";
import { tap } from "rxjs/operators";
import { UtilityService } from "../modules/shared/services/utility.service";
import { LoaderService } from "../modules/shared/services/loader.service";
// import { LOGIN } from "../constant/absolute-routes";
import { PopupService } from "../common/popup";
import { SOMETHING_WENT_WRONG, MESSAGE } from "../constant/message";
import { INVENTORY } from "../constant/routes";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private _utilityService: UtilityService,
    private _loaderService: LoaderService,
    private $popup: PopupService
  ) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!navigator.onLine) {
      this.$popup.error(MESSAGE.OFFLINE, { duration: 4000 });
      this._loaderService.hideLoader();
      return EMPTY;
    }
    // let headers = {};

    let headers:any = {};

    const token = this._utilityService.getAuthToken();
    if (token) {
      headers["authorization"] = "Bearer " + token;
    }
    headers["api_key"] = "123456";
    if (!token || request.url.indexOf('print') !== -1) {
      headers["Authorization"] = "Basic" + " ZWdyZWVuOmVncmVlbkAxMjM=";
    }

    headers["platform"] = "3";
    headers["timezone"] = "480";
    if (request.url.indexOf('blob.core.windows') != -1) {
      headers = {};
    }
    request = request.clone({
      setHeaders: headers
    });

    return next.handle(request).pipe(
      tap(
        data => {
          if (data instanceof HttpResponse) {
            if (request.url.indexOf('blob.core.windows') == -1) {
              this._loaderService.loader.next(false);
            }
          }
        },
        (err: any) => {
          console.log(err);
          this._loaderService.loader.next(false);
          if (err instanceof HttpErrorResponse) {
            if (err.status === 403) {
              window.location.reload();
            }else if(err.status===432){
              //Dont open popup
            }
            else if (err.status == 0) {
              this.$popup.error(MESSAGE.$0);
            } else if (
              err.status === 401 ||
              err.error.responseType == "UNAUTHORIZED" ||
              err.status === 423 ||
              err.status === 410 ||
              err.status === 419 ||
              err.status === 0 || err.status === 411
            ) {
              this.$popup.error(err.error.message);
              this._utilityService.clearStorage();
              this.router.navigate([INVENTORY]);
            } else {
              this.$popup.error(err.error.message);
            }
          }
        }
      )
    );
  }
}
