import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilityService } from '../shared/services/utility.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(
    private _router: Router,
    private _utilityService: UtilityService
  ) {
    if (this._router.url == '/link-expired') {
      this.linkExpired = true;
    }
  }
  linkExpired = false;
  ngOnInit() {
  }
  onBackLogin() {
    if (this._utilityService.getAuthToken()) {
      this._utilityService.clearStorage();
      this._router.navigate(["/account/login"]);
    }
    else { this._router.navigate(["/account/login"]); }
  }

}
