import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LayoutModule } from './modules/layout/layout.module'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HighchartsChartModule } from 'highcharts-angular';
// import { TableComponent } from './common/table/view/table/table.component';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from "./modules/shared/shared.module";
import { AsyncPipe } from "@angular/common";

@NgModule({
  declarations: [
    AppComponent,
    // TableComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    LayoutModule,
    SharedModule,
    BrowserAnimationsModule,
    HighchartsChartModule,
    HttpClientModule,
  ],
  providers: [AsyncPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
