<div class="page-not-found">
  <!-- page not found -->

  <div class="container">

    <div class="row align-item-center">
      <div class="col-sm-4 offset-sm-1">
        <img src="assets/images/pageerror.gif" alt="">
      </div>
      <div class="col-sm-6">
        <h1> 404 </h1>
        <h3> Page not Found </h3>
        <h4> Oops, We can't seem to find the page you're looking for.... </h4>
        <button class="back_btn" (click)="onBackLogin()">Back to Login</button>
      </div>
    </div>
  </div>