import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountGuard } from './guards/account.guard';
import { HomeGuard } from './guards/home.guard';
import { ACCOUNT, INVENTORY, LINK_EXPIRED } from './constant/routes';
import { NotFoundComponent } from './modules/not-found/not-found.component';
const routes: Routes = [
  { path: '', redirectTo: INVENTORY, pathMatch: 'full' },
  {
    path: ACCOUNT, loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule),
    canLoad: [AccountGuard], canActivate: [AccountGuard]
  },
  {
    path: '', loadChildren: () => import('./modules/layout/layout.module').then(m => m.LayoutModule),
    canLoad: [HomeGuard], canActivate: [HomeGuard]
  },
  { path: LINK_EXPIRED, component: NotFoundComponent },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AccountGuard, HomeGuard]
})
export class AppRoutingModule { }
