import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  POPUP_MESSAGES,
  SOMETHING_WENT_WRONG
} from "../../../constant/message";
import { ConfirmationModalComponent } from "../components/confirmation-modal/confirmation-modal.component";

@Injectable()
export class UtilityService {
  constructor(private _dialog: MatDialog, private _snackBar: MatSnackBar) { }
  clearStorage() {
    localStorage.removeItem("jmart_admin-token");
    localStorage.removeItem("jmart_id");
    localStorage.removeItem("jmart_admin-name");
    // localStorage.removeItem("jmartDeviceId");
  }
  type:any
  getAuthToken() {
    return localStorage.getItem("jmart_admin-token");
  }
  setLocalStorage(key:any, value:any) {
    localStorage.setItem(key, value);
  }
  getLocalStorage(key:any) {
    if (localStorage.getItem(key))
      return localStorage.getItem(key);
    else return false;
  }
  removeLocalStorageKey(key:any) {
    if (localStorage.getItem(key))
      localStorage.removeItem(key);
  }

  showAlert(message:any) {
    this._snackBar.open(message, "Close", {
      duration: 3000
    });
  }

  trim(data:any) {
    for (const item in data) {
      if (typeof data[item] === "string") {
        data[item] = data[item].trim();
      }
    }
    return data;
  }

  // errorAlert(error:any) {
  //   let data = {
  //     title: "",
  //     message:
  //       error && error.error && error.error.message
  //         ? error.error.message
  //         : SOMETHING_WENT_WRONG,
  //     yes: POPUP_MESSAGES.close,
  //     hideCancelButton: true
  //   };
  //   this.openDialog(data).subscribe(success => { });
  // }

  openDialog(data:any) {
    const dialogRef = this._dialog.open(ConfirmationModalComponent, {
      width: "500px",
      data: data
    });
    return dialogRef.afterClosed();
  }
  setdeviceToken(deviceToken:any) {
    // if (!localStorage.getItem("jmartDeviceId"))
    localStorage.setItem("jmartDeviceId", deviceToken);
  }
  getdeviceToken() {
    if (localStorage.getItem("jmartDeviceId"))
    { return localStorage.getItem("jmartDeviceId") }
      else {return  "abc" }

  }
}
