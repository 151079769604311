// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    url: 'https://qa-admin-api.vegease.in/',
    adminUrl: 'https://qa-admin-api.vegease.in/egreen/api/v1/',
    // url: 'https://api.egreens.com/egreen/api/v1/', // testing url for client side
    // azure server
    config: {
      AWS_BUCKET: 'egreensapp',
      AWS_ACCESS_KEY: 'AKIA4QD5DZFVC6OAOFMK',
      AWS_SECRET_KEY: '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',
      AWS_REGION: 'ap-south-1'
    },
    firebase: {
      apiKey: "AIzaSyChZjoaPCRIMBxPIbEGD1FH2nKvbUKu2Js",
      authDomain: "vegease-c133f.firebaseapp.com",
      databaseURL: "https://vegease-c133f.firebaseio.com",
      projectId: "vegease-c133f",
      storageBucket: "vegease-c133f.appspot.com",
      messagingSenderId: "18968299831",
      appId: "1:18968299831:web:77205e8beb8e2a47d4f2b2",
      measurementId: "G-2FC2PPLVLC"
    },
    azureConfig: {
      sas: "?sv=2019-12-12&ss=bfqt&srt=sco&sp=rwdlacupx&se=2030-10-06T19:03:23Z&st=2020-10-06T11:03:23Z&spr=https&sig=wYnF%2BZmTeFtIWKhWQRd1eBbamwAlVp4yXZpJHC%2BDmO8%3D",
      storageAccount: "egreens",
      containerName: "egreensapp"
    },
    cdnUrl: "https://cdn.vegease.in/",
    origin: 'LIVE'
  };
  
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/dist/zone-error';  // Included with Angular CLI.
  