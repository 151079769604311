import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PopupService } from '../common/popup';
import { DASHBOARD } from '../constant/absolute-routes';
// import { Module } from '../constant/app-constants';
import { TOAST_MESSAGES } from '../constant/messages';
import { DataTransferService } from '../modules/shared/services/data-transfer.service';
import { UtilityService } from '../modules/shared/services/utility.service';
import { AccessPipe } from '../modules/pipes/access/access.pipe';

@Injectable({
  providedIn: 'root'
})
export class AccessGuard implements CanActivate, CanLoad {
  access: any = {};
  path: any;
  constructor(
    private $dataTransferService: DataTransferService,
    private $popup: PopupService,
    private $router: Router
  ) {

  }
  canLoad(route: Route,
    segments: UrlSegment[]): boolean | Observable<boolean> | Promise<boolean> {
    const accessPipe = new AccessPipe(this.$dataTransferService);

    const module = route.data["module"];
    const action = route.data["action"];

    // console.log('guard module', module, accessPipe.transform(module, action));

    if (
      action
        ? accessPipe.transform(module, action)
        : accessPipe.transform(module)
    ) {
      return true;
    } else {
      // if (module!=Module.DASHBOARD) {
      //   this.$popup.warn(TOAST_MESSAGES.NO_ACCESS(module, action));
      // }
      //  else {
      //   this.$popup.warn(TOAST_MESSAGES.NO_ACCESS(module, action));
      // }
      if (!accessPipe.safeRoute) {
        accessPipe.setSafeRoutes();
      }
      let safeRoute = accessPipe.safeRoute;
      this.$router.navigate([safeRoute]);
      return false;
    }
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    const accessPipe = new AccessPipe(this.$dataTransferService);
    // console.log('route', route);

    const module = route.data["module"];
    const action = route.data["action"];

    // console.log('guard module', module, accessPipe.transform(module, action));

    if (
      action
        ? accessPipe.transform(module, action)
        : accessPipe.transform(module)
    ) {
      return true;
    } else {
      // if (module!=Module.DASHBOARD) {
      //   this.$popup.warn(TOAST_MESSAGES.NO_ACCESS(module, action));
      // }
      if (!accessPipe.safeRoute) {
        accessPipe.setSafeRoutes();
      }
      let safeRoute = accessPipe.safeRoute;
      //  console.log(safeRoute);
      this.$router.navigate([safeRoute]);
      return false;
    }
  }
}
