export function AutoUnsubscribe(blackList = []) {
    return function (constructor:any) {
      const original = constructor.prototype.ngOnDestroy;
      const prop:any[] = []

      constructor.prototype.ngOnDestroy = function () {
        for (const prop of Object.keys(this)) {
         
            let prop : any = [];
            const property = this[prop];

        //   if (!blackList.includes(prop)) {
        //     if (property && typeof property.unsubscribe === 'function') {
        //       property.unsubscribe();
        //     }
        //   }
        }
        if (original && typeof original === 'function') {
          original.apply(this, arguments);
        }
      };
    };
  }
  