import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LayoutRoutingModule } from './layout-routing.module';
import { LayoutComponent } from './layout.component';
import { HeaderComponent } from './layout-parts/header/header.component';
import { FooterComponent } from './layout-parts/footer/footer.component';
import { SidebarComponent } from './layout-parts/sidebar/sidebar.component';

//header
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { AbsoluteRoutingModule } from 'src/app/modules/pipes/absolute-routing/absolute-routing.module';
import { AccessModule } from '../pipes/access/access.module';

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
  ],
  imports: [
    CommonModule,
    LayoutRoutingModule,
    MatDividerModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatToolbarModule,
    MatTooltipModule,
    MatBadgeModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatTableModule,
    AbsoluteRoutingModule,
    AccessModule,
  ],
})
export class LayoutModule {}
