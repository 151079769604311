import { Component, OnInit, ChangeDetectorRef, NgZone } from "@angular/core";
import { Router, NavigationStart, NavigationEnd } from "@angular/router";
import { LoaderService } from "./modules/shared/services/loader.service";
import { AutoUnsubscribe } from './constant/unsubscriber';
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
@AutoUnsubscribe([])
export class AppComponent implements OnInit {
  loader = false;
  consoleMessage: string = "Welcome to EGreens Store";
  consoleStyle: string = "color: #0099B8; font-size: 28px;font-weight: bold;";
  constructor(
    private _router: Router,
    private _loaderService: LoaderService,
    private ch: ChangeDetectorRef,
    private ngZone: NgZone
  ) {
    console.log(`%c${this.consoleMessage}`, this.consoleStyle);
  }

  ngOnInit() {
    this.listenRouterEvents();
  }
  /**
   * Method For Initiating and stopping loader on route change
   */
  listenRouterEvents() {
    this._router.events.forEach(event => {
      if (event instanceof NavigationStart) {
        this._loaderService.showLoader();
      } else if (event instanceof NavigationEnd) {
        this._loaderService.hideLoader();
      }
    });
    this._loaderService.loader.subscribe(data => {
      setTimeout(() => {
        this.loader = data;
      });
    });
  }
  reloadComponent(link) {
    if (link === this._router.url) {
      this._router.routeReuseStrategy.shouldReuseRoute = () => false;
      this._router.onSameUrlNavigation = 'reload';
      this.ngZone.run(() => this._router.navigate([link]));
      // this._router.navigate([link]);
    }
    else {
      return;
    }

  }
}
