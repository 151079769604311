<div class="page-sidebar">
  <mat-list class="sidebar-list">
    <!-- <mat-list-item *ngIf="MODULE_NAME.DASHBOARD | access" [routerLink]="['DASHBOARD' | absolutePath]"
      routerLinkActive="active" matRipple matTooltip="Orders" titlePosition="right">
      <mat-icon>dashboard</mat-icon> <span>Dashboard</span>
    </mat-list-item>
    <mat-divider *ngIf="MODULE_NAME.DASHBOARD | access"></mat-divider> -->
    <!-- <mat-list-item *ngIf="MODULE_NAME.INVENTORY|access" routerLinkActive="active"
      [routerLink]="['INVENTORY'|absolutePath]" matRipple matTooltip="Inventory" titlePosition="right">
      <mat-icon>pending_actions</mat-icon> <span>Inventory</span>
    </mat-list-item>
    <mat-divider *ngIf="MODULE_NAME.INVENTORY|access"></mat-divider> -->
    <mat-expansion-panel *ngIf="MODULE_NAME.INVENTORY | access" #inventory hideToggle="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-list-item class="parent" matRipple matTooltip="Inventory" titlePosition="right">
            <mat-icon>inventory</mat-icon>
            <span class="material-icons-outlined"> Inventory </span>
          </mat-list-item>
        </mat-panel-title>
        <mat-panel-description class="align-items-center">
          <mat-icon *ngIf="!inventory.expanded">keyboard_arrow_right</mat-icon>
          <mat-icon *ngIf="inventory.expanded">keyboard_arrow_down</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div>
        <mat-list-item routerLinkActive="active" [routerLink]="['INVENTORY' | absolutePath]" matRipple
          matTooltip="Live Inventory" titlePosition="right">
          <mat-icon>pending_actions</mat-icon>
          <span>Live</span>
        </mat-list-item>
      </div>
      <div>
        <mat-list-item routerLinkActive="active" [routerLink]="['DAYEND' | absolutePath]" matRipple
        matTooltip="Day End" titlePosition="right">
        <mat-icon>event</mat-icon>
        <span>Day End</span>
       </mat-list-item>
      </div>
    </mat-expansion-panel>
  </mat-list>
</div>