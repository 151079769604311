import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { LoaderService } from './loader.service';


@Injectable({
    providedIn: 'root'
})
export class HttpService {
    private apiUrl: string;
    private adminApiUrl: string
    constructor(
        private http: HttpClient,
        private _loaderService: LoaderService,
    ) {
        this.apiUrl = environment.url;
        this.adminApiUrl = environment.adminUrl;
    }
    postEgreenApi<T>(url: any, data?: any, query?: any, loader = true): Observable<Common.ApiResponse<T>> {
        if (loader) {
            this._loaderService.loader.next(loader);
        }
        if (query) {
            return this.http.post<Common.ApiResponse<T>>(this.adminApiUrl + url, data, { params: query });
        }
        return this.http.post<Common.ApiResponse<T>>(this.adminApiUrl + url, data);
    }
    getEgreenApi<T>(url, httpParams?: any, loader = true): Observable<Common.ApiResponse<T>> {
        if (loader) {
            this._loaderService.loader.next(loader);
        }
        for (let item in httpParams) {
            if (httpParams[item] === '' || httpParams[item] === undefined || httpParams[item] === null) {
                delete httpParams[item];
            }

        }
        const header = {};
        if (httpParams) {
            header['params'] = httpParams;
        }
        return this.http.get<Common.ApiResponse<T>>(this.adminApiUrl + url, header);
    }
    put<T>(url: any, data: any, loader = true): Observable<Common.ApiResponse<T>> {
        if (loader) {
            this._loaderService.loader.next(loader);
        }
        return this.http.put<Common.ApiResponse<T>>(this.apiUrl + url, data);
    }
    post<T>(url: any, data: any, query: any, loader = true): Observable<Common.ApiResponse<T>> {
        if (loader) {
            this._loaderService.loader.next(loader);
        }
        if (query) {
            return this.http.post<Common.ApiResponse<T>>(this.apiUrl + url, data, { params: query });
        }
        return this.http.post<Common.ApiResponse<T>>(this.apiUrl + url, data);
    }
    patch<T>(url: any, data: any, loader = true): Observable<Common.ApiResponse<T>> {
        if (loader) {
            this._loaderService.loader.next(loader);
        }
        return this.http.patch<Common.ApiResponse<T>>(this.apiUrl + url, data);
    }
    get<T>(url, httpParams?: any, loader = true): Observable<Common.ApiResponse<T>> {
        if (loader) {
            this._loaderService.loader.next(loader);
        }
        for (let item in httpParams) {
            if (httpParams[item] === '' || httpParams[item] === undefined || httpParams[item] === null) {
                delete httpParams[item];
            }

        }
        const header = {};
        if (httpParams) {
            header['params'] = httpParams;
        }
        return this.http.get<Common.ApiResponse<T>>(this.apiUrl + url, header);
    }
    delete(url: any, httpParams: any, loader = true) {
        if (loader) {
            this._loaderService.loader.next(loader);
        }
        for (let item in httpParams) {
            if (httpParams[item] === '' || httpParams[item] === undefined || httpParams[item] === null || httpParams[item] === []) {
                delete httpParams[item];
            }
        }
        const header = {};
        // if (httpParams) {
        //     header['params'] = httpParams;
        // }
        return this.http.delete(this.apiUrl + url, httpParams);
    }
    getBlobRequest(url: any, queryParams?: any): Observable<Blob> {
        this._loaderService.loader.next(true);
        return this.http.get(this.apiUrl + url, { params: queryParams, responseType: "blob" });
    }
}