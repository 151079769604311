
export const VALIDATION_MESSAGES = {
    "confirm password": {
        matchPassword: "Confirm password is not matched with password"
    }
}
export const MESSAGE = {
    OFFLINE: 'You are offline, please connect to internet and retry',
    SERVER_NOT_FOUND: 'Can not found server',
    $0: 'Something went wrong, Please try again.'
}

export const POPUP_MESSAGES = {
    ok: 'Ok',
    close: 'Close',
    confrim: 'Confirmation',
    no: 'No',
    yes: 'Yes',
    passwordResetTitle: 'Reset Password',
    passwordResetLink: 'Password reset link has been sent to registered email id . Please follow the link to reset password .',
    profileEdited: 'Profile has been Edited successfully.',
    passwordChanged: 'Password has been changed successfully.',
    passwordChangedTitle: 'Change Password',
    invalidResetPasswordLink: 'Reset password link is expired',
    logout: 'Logout',
    logoutConfirmation: 'Do you wish to logout?',
    forgotPasswordTitle: "Forgot Your Password? Don't worry send us your registered email address and we will send yousteps to reset your password.",
    CSV_FILE: 'Please select a xlxs file',
    OTP_RESENT: "OTP resent successfully",
    acceptPO: 'Do want to accept the product ?',
    rejectPO: 'Do want to reject the product ?',
    completePO: 'Do want to complete the purchase order ?',
    noneRoleCondition: `All roles shouldn't be none`,
    deleteSubAdmin: 'Do you want to delete sub-admin ?',
    cancelOrder: `Do you want to cancel the order?`,
};

export const invalidImageError = (format = 'jpeg/png') => `Only ${format} images are allowed`;

export const invalidFileSize = (size = 4) => `File size can not be more than ${size} MB`;

export const COMMON_MESSAGES = {
    ADDED: (type:any) => toTitleCase(type) + " has been added successfully",
    UPDATED: (type:any) => toTitleCase(type) + " has been updated successfully",
    BLOCKED: {
        confirm: (type:any) => `Do you want to block this ${type.toLowerCase()}?`,
        success: (type:any) => `${toTitleCase(type)} has been blocked successfully`
    },
    ACTIVE: {
        confirm: (type:any) => `Do you want to unblock this ${type.toLowerCase()}?`,
        success: (type:any) => `${toTitleCase(type)} has been unblocked successfully`
    },
    DELETED: {
        confirm: (type:any) => `Do you want to delete this ${type.toLowerCase()}?`,
        success: (type:any) => `${toTitleCase(type)} has been deleted successfully`
    }
};

export const SOMETHING_WENT_WRONG = 'Something went wrong , Please try again later.';

export const toTitleCase = (str:any) => {
    return str.replace(
        /\w\S*/g,
        function (txt:any) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

export const FILE_MESSAGES_ERROR = {
    images: 'Please add images'
}

export enum STATUS {
    BLOCKED = "BLOCKED",
    UNBLOCKED = "UNBLOCKED",
    DELETED = "DELETED"
}


export const FILE_ERROR_MESSAGES = {
    images: 'Please add images',
    profilePicture: 'Please add profile picture',
    document: 'Please add document',
    googleSuggestion: 'Please select address from google suggestion',
}

export const PROFILE_SIZE = "Profile picture should be less than 10 MB";
export const ASSIGN_MESSAGE = "There is no free vehicle available please add some vehicle";




export const invalidVideoError = (format = "mp4/webm/ogg") => `Only ${format} videos are allowed`;

export const TOAST_MESSAGES = {
    NO_ACCESS: (module:any, action:any) =>
        `You do not have access`,
};