<div class="page-confirmation-modal">
  <h5 class="confirmation-heading">{{title}}</h5>
  <div class="confirmation-body">

    <div class="modal_message">
      <p>{{message}}</p>
      <div *ngIf="showTextBox" mat-dialog-actionst>
        <h4 style="text-align:center ;margin-top: 20px;">Reason for This Action</h4>
        <mat-form-field>
          <textarea matInput placeholder="Type here.." [(ngModel)]="responseData.note" name="note"></textarea>
        </mat-form-field>
      </div>
    </div>

    <div class="btn-cover">
      <button class="btn btn-simple" *ngIf="showCancelButton" (click)="onNoClick()">
        {{no}}
      </button>
      <button class="btn btn-primary" [disabled]="(!responseData||!responseData.note||responseData.note.trim()=='') && showTextBox"
        [mat-dialog-close]="responseData" (click)="confirm()">
        {{yes}}
      </button>
    </div>

  </div>
</div>