import { INVENTORY, DASHBOARD } from './absolute-routes';
export const DEFAULT_PAGE_SIZE = 25;
export enum Module {
    INVENTORY_LIST = 'inventoryList',
    DASHBOARD = 'dashboard',
    INVENTORY = 'inventoryGraphs',
    SUBADMIN = 'subadmin',
    DAYEND = 'day-end',

}

export const Subadmin_permission = [
    { Manage_Type: Module.INVENTORY, Module_Name: 'Inventory', none: 1, view: 2, modify: 3, path: INVENTORY },
];
export enum Action {
    NONE = 1,
    VEIW = 2,
    MODIFY = 3
}
export const PERMISSION = [
    { Manage_Type: "inventory", Module_Name: 'Inventory', none: 1, view: 2, modify: 3, path: INVENTORY },
];