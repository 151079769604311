<mat-toolbar class="page-header">
  <mat-toolbar-row>
    <div class="left-portion">
      <span class="material-icons hamburger-icon" (click)="sidebarCollaped()">menu</span>
      <div class="logo-cover">
        <img [routerLink]="['']" [src]="logo" alt="Logo">
      </div>
    </div>
    <h3>{{adminName ? adminName : 'Egreen'}}</h3>
    <div class="btn_group">
      <!-- <button mat-button (click)="logout()" matTooltip="Logout"> -->
      <mat-icon style="color: #089e8f;" (click)="logout()" matTooltip="Logout">exit_to_app</mat-icon>
      <!-- </button> -->
      <mat-menu #menu2="matMenu">
        <div class="setting-popup-header">
          <h6>{{adminName ? adminName : 'Sub-Admin Egreen'}} Profile</h6>
        </div>
        <div class="setting-popup-body">
          <div class="img-cover">
            <img src="../../../../../assets/images/img_avatar3.png" alt="Admin"
              [routerLink]="['ADMIN_PROFILE'|absolutePath]">
          </div>
          <h6 [routerLink]="['ADMIN_PROFILE'|absolutePath]">{{profileDetail?.name}}</h6>
        </div>
      </mat-menu>
    </div>
  </mat-toolbar-row>
</mat-toolbar>